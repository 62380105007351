/* eslint-disable @typescript-eslint/no-explicit-any */
import ApiService from 'app/components/ProfileManagement/Helper/api-service'
import { EnableThirdPartyRequest } from '../Model'
import {
  GET_THIRD_PARTY_SUBS,
  POST_ENABLE_THIRD_PARTY,
} from 'app/components/ProfileManagement/Helper/constants'

export const GetThirdPartySubsAPI = async (id: number | null): Promise<any> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${GET_THIRD_PARTY_SUBS}`
  const url = id ? `${ENDPOINT}?customerid=${id}` : `${ENDPOINT}`
  const response = await ApiService.get(url).catch(function (error) {
    throw error
  })
  return response?.data
}

export const PostEnableThirdPartyAPI = async (details: EnableThirdPartyRequest): Promise<any> => {
  const BASE_URL = process.env.REACT_APP_ECHO_BASE_URL
  const ENDPOINT = `${BASE_URL}${POST_ENABLE_THIRD_PARTY}`
  const response = await ApiService.post(ENDPOINT, details).catch(function (error) {
    throw error
  })
  return response?.data
}
