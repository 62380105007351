import { ILinkDetails } from '../../NavigationPanel/Interfaces/navigationPanelInterface'
// -- Echo Endpoints
// api/billing
export const GET_VALID_CREDIT_CARDS = '/api/Billing/GetCCTypes'
export const GET_COUNTRIES = '/api/Billing/GetBillingCountries'
export const GET_STATES = '/api/Billing/GetBillingStates'
export const GET_CURRENT_CREDIT_CARD = '/api/Billing/GetCurrentBilling'
export const POST_BILLING = '/api/Billing/SaveBilling'
// api/Broker
export const GET_CUSTOMER_LIST = '/api/Broker/GetBrokerCustomers'
export const GET_IS_GAIN_BROKER = '/api/Broker/GetIsGainBroker'

export const GET_IS_GAIN_USER = '/api/Common/GetIsGainUser'
// api/CustomerSupport
export const POST_SUPPORT_EMAIL = '/api/CustomerSupport/SendCustomerSupportEmail'
// api/Demo
export const CREATE_BROKER_DEMO = '/api/Demo/CreateBrokerCustomerDemo'
export const CREATE_CUSTOMER_DEMO = '/api/Demo/CreateCustomerDemo'
export const GET_DEMO_SCODE = '/api/Demo/GetBrokerScode'
export const GET_DEMO_BROKER_WSCODE = '/api/Demo/GetBrokersWithScode'
export const GET_DEMO_ACCOUNT = '/api/Demo/GetDemo'
export const RESET_BAL_DEMO_ACCOUNT = '/api/Demo/ResetBalance'
export const UPDATE_DEMO_PASSWORD = '/api/Demo/UpdateDemo'
export const UPDATE_DEMO_ACCOUNT = '/api/Demo/UpdateDemo'
export const UPDATE_DEMO_TRIAL = '/api/Demo/UpdateDemo'
// api/MarketData
export const GET_IS_CERTIFIED = '/api/MarketData/GetIsCertified'
export const GET_SUBMIT_PROFESSIONAL_ID = '/api/MarketData/SubmitCertified'
export const GET_MARKET_DATA = '/api/MarketData/GetMarketData'
export const POST_SAVE_MARKET_DATA_ENTITLEMENTS = '/api/MarketData/SaveMarketDataEntitlements'
// billing
export const GET_MULT_BILL_CYCLES = '/api/Demo/GetMultBillCycles'
export const GET_ONE_TIME_BILL_CYCLES = '/api/Demo/GetOneTimeBillCycles'
export const SAVE_BILL_CYCLE_ENROLL_DEMO = '/api/Demo/BillCycleEnrollDemo'
export const UNSUBSCRIBE_BILL_CYCLE_DEMO = '/api/Demo/UnsubscribeFromBillingCycle'
// api/Profile
export const GET_PERSONAL_INFO_PROXY = '/api/Profile/PersonalInfo'
export const GET_PERSONAL_INFO_CNTRY_PROXY = '/api/Profile/Country'
export const GET_ACCOUNTS_PROXY = '/api/Profile/Account'
export const GET_ACCOUNT_ADDRESSES_PROXY = '/api/Profile/AccountAddresses'
export const UPDATE_PERSONAL_DETAILS = '/api/Profile/UpdateProfile'
export const UPDATE_CONTACT_ADDRESS = '/api/Profile/UpdateContactAddress'
export const UPDATE_ACCOUNT_ADDRESS = '/api/Profile/UpdateAccountAddress'
export const UPDATE_PROFILE_STATUS = '/api/Profile/UpdateProfileStatus'
// api/ThirdParty
export const GET_THIRD_PARTY_SUBS = '/api/ThirdParty/ActiveSubscriptions'
export const POST_ENABLE_THIRD_PARTY = '/api/ThirdParty/SetEnabled'
// api/whitelabel
export const WHITELABEL_SERVICE_API = '/api/whitelabel/whitelabels/'
export const SOFTWARE_DOWNLOADS_API = '/api/Software/GetWhitelablesForDownloads'
export const GET_CUSTOMER_CDS_ID = '/api/Broker/GetCustomerCdsId'
// Entitlements
export const ACTIVE_SUBSCRIPTION_API = '/api/Entitlements/SoftwareWithCurrentSubscription'
export const UPDATE_SUBSCRIPTION_API = '/api/Entitlements/UpdateSoftwareSubscription'
// Software
export const SUBSCRIPTIONS_API = '/api/Software'
// LiveAccounts
export const ACCOUNTS_API = '/api/Accounts/'
// -- /Echo Endpoints

export const LOGIN = '/login'
export const REDIRECT_URI = '/services/authcallback/SecureAuthOIDC'
export const LOGOUT = '/logout'
export const POST_LOGOUT_URI = '/services/signout'

// Constants
export const ACCOUNT_SETTINGS_HOME = 'Accounts & Settings Home'
export const PERSONAL_DETAILS = 'Personal Details'
export const PERSONAL_DETAILS_TRANSLATE_KEY = 't__PersonalDetails'
export const ACCOUNT_DETAILS = 'Account Details'
export const ACCOUNT_DETAILS_TRANSLATE_KEY = 't__AccountDetails'
export const DOWNLOAD_SOFTWARE = 'Download Software'
export const DOWNLOAD_SOFTWARE_TRANSLATE_KEY = 't__DownloadSoftware'
export const MARKET_DATA_SUBSCRIPTIONS = 'Market Data Subscriptions'
export const MARKET_DATA_SUBSCRIPTIONS_TRANSLATE_KEY = 't__MarketDataSubscriptions'
export const MANAGE_SOFTWARE_SUBSCRIPTIONS = 'Manage Software Subscriptions'
export const MANAGE_SOFTWARE_SUBSCRIPTIONS_TRANSLATE_KEY = 't__ManageSoftwareSubscriptions'
export const THIRD_PARTY_PLATFORMS = '3rd Party Platforms'
export const THIRD_PARTY_PLATFORMS_TRANSLATE_KEY = 't__3rdPartyPlatforms'

export const CREATE_DEMO_ACCOUNT = 'Manage Demo Account'
export const MANAGE_DEMO_ACCOUNT_TRANSLATE_KEY = 'Manage Demo Account'

export const PERSONAL_DETAILS_ROUTE = 'personal-details'
export const ACCOUNT_DETAILS_ROUTE = 'account-details'
export const DOWNLOAD_SOFTWARE_ROUTE = 'download-software'
export const MARKET_DATA_SUBSCRIPTIONS_ROUTE = 'market-data-subscriptions'
export const MANAGE_SOFTWARE_SUBSCRIPTIONS_ROUTE = 'manage-software-subscriptions'
// export const THIRD_PARTY_PLATFORMS_ROUTE = 'third-party-platforms'
// export const MANAGE_DEMO_ACCOUNT_ROUTE = 'manage-demo'
// export const CREATE_DEMO_ACCOUNT_ROUTE = 'manage-demo'

export const GAIN_USER_SUPPORT_EMAIL = 'GFSupport@StoneX.com'
export const NON_GAIN_USER_SUPPORT_EMAIL = 'CS@StoneX.com'

export const HIDE_LEFT_NAV_SIZE = 1000
export const INTRODUCING_BROKER = 'INTRODUCING BROKER'
export const EMPLOYEE = 'EMPLOYEE'

export const SUBMENU_ITEMS = [
  DOWNLOAD_SOFTWARE,
  MANAGE_SOFTWARE_SUBSCRIPTIONS,
  //THIRD_PARTY_PLATFORMS,
  CREATE_DEMO_ACCOUNT,
]

export const PROFILE_MANAGEMENT_SUBMENU: ILinkDetails[] = [
  // { linkName: 'Accounts & Settings Home', linkUrl: '' },
  {
    linkName: PERSONAL_DETAILS,
    translateKey: PERSONAL_DETAILS_TRANSLATE_KEY,
    linkUrl: PERSONAL_DETAILS_ROUTE,
    isFuturesTradingTab: false,
    isEnabled: true,
  },
  {
    linkName: ACCOUNT_DETAILS,
    translateKey: ACCOUNT_DETAILS_TRANSLATE_KEY,
    linkUrl: ACCOUNT_DETAILS_ROUTE,
    isFuturesTradingTab: false,
    isEnabled: true,
  },
]

export const PROFILE_MANAGEMENT_SUBMENU_BROKER: ILinkDetails[] = [
  // { linkName: 'Accounts & Settings Home', linkUrl: '' },
  {
    linkName: PERSONAL_DETAILS,
    translateKey: PERSONAL_DETAILS_TRANSLATE_KEY,
    linkUrl: PERSONAL_DETAILS_ROUTE,
    isFuturesTradingTab: false,
    isEnabled: true,
  },
]
const enableMarketDataSubs = process.env.REACT_APP_SHOW_MARKET_DATA === 'true'
export const FUTURES_TRADING_SOFTWARE_SUBMENU: ILinkDetails[] = enableMarketDataSubs
  ? [
      {
        linkName: DOWNLOAD_SOFTWARE,
        translateKey: DOWNLOAD_SOFTWARE_TRANSLATE_KEY,
        linkUrl: DOWNLOAD_SOFTWARE_ROUTE,
        isFuturesTradingTab: true,
        isEnabled: true,
      },
      {
        linkName: MARKET_DATA_SUBSCRIPTIONS,
        translateKey: MARKET_DATA_SUBSCRIPTIONS_TRANSLATE_KEY,
        linkUrl: MARKET_DATA_SUBSCRIPTIONS_ROUTE,
        isFuturesTradingTab: true,
        isEnabled: true,
      },
      {
        linkName: MANAGE_SOFTWARE_SUBSCRIPTIONS,
        translateKey: MANAGE_SOFTWARE_SUBSCRIPTIONS_TRANSLATE_KEY,
        linkUrl: MANAGE_SOFTWARE_SUBSCRIPTIONS_ROUTE,
        isFuturesTradingTab: true,
        isEnabled: true,
      },
      // {
      //   linkName: THIRD_PARTY_PLATFORMS,
      //   translateKey: THIRD_PARTY_PLATFORMS_TRANSLATE_KEY,
      //   linkUrl: THIRD_PARTY_PLATFORMS_ROUTE,
      //   isFuturesTradingTab: true,
      //   isEnabled: true,
      // },
      // {
      //   linkName: CREATE_DEMO_ACCOUNT,
      //   translateKey: MANAGE_DEMO_ACCOUNT_TRANSLATE_KEY,
      //   linkUrl: CREATE_DEMO_ACCOUNT_ROUTE,
      //   isFuturesTradingTab: true,
      //   isEnabled: process.env.REACT_APP_DEMO_ENABLED === 'true',
      // },
    ]
  : [
      {
        linkName: DOWNLOAD_SOFTWARE,
        translateKey: DOWNLOAD_SOFTWARE_TRANSLATE_KEY,
        linkUrl: DOWNLOAD_SOFTWARE_ROUTE,
        isFuturesTradingTab: true,
        isEnabled: true,
      },
      {
        linkName: MANAGE_SOFTWARE_SUBSCRIPTIONS,
        translateKey: MANAGE_SOFTWARE_SUBSCRIPTIONS_TRANSLATE_KEY,
        linkUrl: MANAGE_SOFTWARE_SUBSCRIPTIONS_ROUTE,
        isFuturesTradingTab: true,
        isEnabled: true,
      },
      // {
      //   linkName: THIRD_PARTY_PLATFORMS,
      //   translateKey: THIRD_PARTY_PLATFORMS_TRANSLATE_KEY,
      //   linkUrl: THIRD_PARTY_PLATFORMS_ROUTE,
      //   isFuturesTradingTab: true,
      //   isEnabled: true,
      // },
      // {
      //   linkName: CREATE_DEMO_ACCOUNT,
      //   translateKey: MANAGE_DEMO_ACCOUNT_TRANSLATE_KEY,
      //   linkUrl: CREATE_DEMO_ACCOUNT_ROUTE,
      //   isFuturesTradingTab: true,
      //   isEnabled: process.env.REACT_APP_DEMO_ENABLED === 'true',
      // },
    ]
